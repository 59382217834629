import Link from "next/link";
import { LeaguePlayerWithTeam } from "../models/LeaguePlayer";
import { PlayerImage, TeamImage } from "./common/Images";
import { NoItems } from "./common/NoItems";

interface PlayerRankingTableProps {
  players: LeaguePlayerWithTeam[];
}
export function PlayerRankingTable({ players }: PlayerRankingTableProps) {
  if (players.length === 0) return <NoItems />;
  return (
    <div className="table_scrollable max-w-[900px]">
      <table>
        <tbody>
          <tr className="table-header row_item break-words">
            <th className="text-center w-4">Rang</th>
            <th className="w-4">Club</th>
            <th className=" w-4">Name</th>
            <th className="text-center w-4">+/-</th>
          </tr>
          {players
            .sort((a, b) => a.rank - b.rank)
            .map((p) => (
              <PlayerTableCard player={p} key={p.id} />
            ))}
        </tbody>
      </table>
    </div>
  );
}

interface PlayerTableCardProps {
  player: LeaguePlayerWithTeam;
}
function PlayerTableCard({ player }: PlayerTableCardProps) {
  return (
    <tr>
      <td>
        <div className="text-center">{player.rank}</div>
      </td>
      <td>
        <div>
          <TeamImage
            team={player.seasonPlayer.seasonTeam}
            contain={false}
            size="md"
            className="shrink-0"
          />
        </div>
      </td>
      <td>
        <Link
          className="flex gap-4"
          href={`/bundesliga/players/${player.id}`}
          title={`${player.seasonPlayer.firstName} ${player.seasonPlayer.lastName}`}
        >
          <PlayerImage player={player.seasonPlayer} size="sm" />
          <div>{player.seasonPlayer.lastName}</div>
          <div>{player.seasonPlayer.firstName}</div>
        </Link>
      </td>
      <td className="text-center">{player.gameDifference}</td>
    </tr>
  );
}
